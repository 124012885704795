import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d396c3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "catalog-section" }
const _hoisted_2 = { class: "catalog-section__title" }
const _hoisted_3 = { class: "catalog-section__container" }
const _hoisted_4 = { class: "catalog-section__filters" }
const _hoisted_5 = {
  key: 0,
  class: "preloader"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["src"]
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filter = _resolveComponent("Filter")!
  const _component_MainCard = _resolveComponent("MainCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(this.dataNames.catalog), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Filter, {
          sort: true,
          price: true,
          dataTitleCheckbox: ['Rarity'],
          dataBodyCheckbox: this.dataBodyCheckbox,
          cardCount: 4
        }, null, 8, ["dataBodyCheckbox"])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.data, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "catalog-section__cards-wrap"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i.id,
              class: "catalog-section__cards"
            }, [
              _createVNode(_component_MainCard, {
                name: i.name,
                attributes: i.attributes,
                imgCatalog: i.image,
                onClick: ($event: any) => (_ctx.goToCatalogDetails(i.mint))
              }, null, 8, ["name", "attributes", "imgCatalog", "onClick"])
            ]))
          }), 128))
        ]))
      }), 128))
    ]),
    (this.data.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.dataTestOpencia, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i.id
          }, [
            (i.payment_token_contract)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("img", {
                    src: i.asset.image_url,
                    alt: ""
                  }, null, 8, _hoisted_7),
                  (i.payment_token_contract.usd_price !== null)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(i.payment_token_contract.usd_price) + " USD ", 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", null, _toDisplayString(i.payment_token_contract.eth_price) + " ETH", 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}