
import { defineComponent, ref } from "vue";
import Filter from "@/components/molecules/Filter.vue";
import MainCard from "@/components/molecules/MainCard.vue";
import axios from "axios";

export default defineComponent({
  name: "CatalogSection",
  data() {
    return {
      dataNames: {
        catalog: "Catalog",
      },
      data: {},
      dataTestOpencia: {},
      dataBodyCheckbox: [
        {
          Rarity: [
            "Bronze",
            "Silver",
            "Gold",
            "Platinum",
            "Diamond",
            "Legendary",
          ],
        },
      ],
    };
  },
  components: {
    Filter,
    MainCard,
  },
  mounted() {
    this.getClientCatalog();
    //test opencia api
    // this.getOpenci()
  },
  methods: {
    goToCatalogDetails(id) {
      this.$router.push(`/dashboard/catalog/details/${id}`);
    },
    getClientCatalog() {
      this.$store.dispatch("getNFTs/getClientCatalog").then(
        (response) => {
          this.data = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getOpenci() {
      axios
        .get(
          `https://testnets-api.opensea.io/wyvern/v1/orders?bundled=false&include_bundled=false&side=1&limit=1&offset=0&order_by=created_date&order_direction=desc`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.dataTestOpencia = response.data;
        });
    },
  },
});
